<template>
  <div class="row">
    <div class="col-md-12">
      <div
        id="filter-card"
        class="row justify-content-start items-baseline w-100 mb-2"
        style="margin-bottom: -5px"
      >
        <span
          class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2"
          @click="$router.go(-1)"
        >
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 id="page-title" class="d-inline-block">{{ $t("qr_scanner.title") }}</h3>
      </div>

      <dashboard-box>
        <template v-slot:title>
          <span>{{ $t("general.meetings") }}</span>
        </template>
        <template v-slot:preview>
          <div class="container" v-if="!isUserGranted('Staff', ['supplierCompanyStaff'], false, true)">
            <div class="row mt-2 d-flex justify-content-center">
              <div class="col-12 col-md-6">
                <qrcode-stream
                  :camera="camera"
                  :key="_uid"
                  :track="paintBoundingBox"
                  @decode="onDecode"
                  @init="logErrors"
                >
                  <div v-if="isValid == true" class="validation-success">
                    Lütfen Bekleyin Yönlendiriliyorsunuz
                  </div>

                  <div v-if="isValid == 'error'" class="validation-failure">
                    Link Bulunamadı
                  </div>
                </qrcode-stream>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

import DashboardBox from "@/assets/components/DashboardBox";
import { GET_ITEMS } from "@/core/services/store/qr-scanner/qr_scanner.module";
export default {
  name: "QRCodeScanner",
  components: {
    DashboardBox,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  data() {
    return {
      camera: "auto",
      result: null,
      isValid: false,
      moduleNames: [
        "time-sheet",
        "samples",
        "order-pdf",
        "waybill-tag",
        "waybills/get-by-code",
        "order-expense",
        "order-ready-made-request",
        "order-raw-fabric-request",
        "order-print-request",
        "order-paint-request",
        "order-knit-request",
        "order-yarn-request",
        "order-addition-request",
        //Samples Start
        "fabric-request-ready-purchases",
        "fabric-request-raw-fabrics",
        "fabric-request-yarns",
        "fabric-request-knits",
        "fabric-request-paints",
        "fabric-request-prints",
        "fabric-request-additions"
      ],
      forIdsModuleRoutes: {
        samples: {
          route: "sample.product.edit",
          request: false,
        },
        "time-sheet": {
          route: "time-sheet.edit",
          request: false,
        },
        "order-pdf": {
          route: "order.edit",
          request: false,
          params: {
            type: 1,
          },
        },
        "waybill-tag": {
          route: "waybill.detail",
          request: true,
          url: "api/waybills-show-qr-scanner",
          type: "transaction",
        },
        "waybills/get-by-code": {
          route: "waybill.detail",
          request: true,
          url: "api/waybills-show-qr-scanner",
          type: "waybill",
        },
        "order-expense": {
          route: "order.edit",
          request: true,
          url: "api/order-expense-qr-scanner",
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "expense",
          },
        },
        "order-ready-made-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "order-ready-request",
          },
        },
        "order-raw-fabric-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "raw-fabric",
          },
        },
        "order-print-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "order-print",
          },
        },
        "order-paint-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "order-paint",
          },
        },
        "order-knit-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "knit-request",
          },
        },
        "order-yarn-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "yarn-request",
          },
        },
        "order-addition-request": {
          route: "order.edit",
          request: true,
          params: {
            type: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "addition-request",
          },
        },
        //Samples Start
        "fabric-request-ready-purchases":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "fabric-request-ready",
          },
        },
        "fabric-request-raw-fabrics":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "request-raw-fabrics",
          },
        },
        "fabric-request-yarns":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "request-yarns",
          },
        },
        "fabric-request-knits":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "request-knits",
          },
        },
        "fabric-request-paints":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "request-paints",
          },
        },
        "fabric-request-prints":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "request-prints",
          },
        },
        "fabric-request-additions":{
          route: "sample.request.fabric",
          request: true,
          params: {
            requestType: 1,
          },
          query: {
            qr_scanner: true,
            qr_type: "addition-request",
          },
        }
      },
    };
  },

  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#146C94";
        ctx.strokeRect(x, y, width, height);
      }
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;

        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;

        const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width);

        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = "center";

        ctx.lineWidth = 3;
        ctx.strokeStyle = "#35495e";
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);

        ctx.fillStyle = "#5cb984";
        ctx.fillText(rawValue, centerX, centerY);
      }
    },

    async findRoute(content, moduleName) {
      let params = {};
      let urlParts = _.split(content, "/");
      let index = _.indexOf(urlParts, moduleName);
      let id = urlParts[index + 1];

      if (_.includes(this.moduleNames, moduleName)) {
        let item = this.forIdsModuleRoutes[moduleName];
        let requestQuery = {};
        if (item.request) {
          await this.$store
            .dispatch(GET_ITEMS, {
              url: item.url ? item.url : content,
              filters: {
                id: item.url ? id : "",
                type: item.type ? item.type : null,
              },
            })
            .then((result) => {
              id = result.data.id;
              requestQuery = result.data.query ? result.data.query : result.data;
              params=result.data.params ? {...result.data.params} : {}
            });
        }
        console.log(requestQuery);
        if (item.params) {
          let itemParams = { ...item.params, id: id };
          params.params = {
            ...params,
            ...itemParams,
          };
        } else {
          params ={params: {
            id: id,
          }};
        }
        if (item.query) {
          let itemQuery = { ...item.query, ...requestQuery };
          params.query = { ...itemQuery };
        }
      } else {
        return false;
      }
      console.log(params)
      if (id && index != -1) {
        return params;
      } else {
        return false;
      }
    },

    async redirectRoute(name, params) {
      if (params) {
        this.isValid = true;
        await this.timeout(1000);
        let tmpParams = {};
        tmpParams.name = this.forIdsModuleRoutes[name].route;
        if (params.params) {
          tmpParams.params = params.params;
        }
        if (params.query) {
          tmpParams.query = params.query;
        }
        this.$router.push(tmpParams);
        return false;
      }

      this.isValid = "error";
      await this.timeout(1000);
      this.isValid = false;
      this.turnCameraOn();
      this.resetItems();
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();

      this.moduleNames.forEach(async (element) => {
        if (_.includes(content, element)) {
          let params = await this.findRoute(content, element);
          console.log(params);
          await this.redirectRoute(element, params);
        }else{
          this.isValid = "error";
          await this.timeout(1000);
          this.isValid = false;
          this.turnCameraOn();
          this.resetItems();
        }
      });

      this.isValid = false;
      this.turnCameraOn();
      this.resetItems();
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    logErrors(promise) {
      promise.catch(console.error);
    },
    resetItems() {
      this.camera = "auto";
      this.result = null;
      this.isValid = false;
    },
  },
  mounted() {

    if(this.isUserGranted('Staff', ['supplierCompanyStaff'], false,true)){
      return;
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("general.dashboard"), route: "dashboard" },
        { title: this.$t("qr_scanner.title") },
      ]);
      this.resetItems();
    }
    
    
  },
};
</script>

<style scoped>
.qr-code-area {
  height: 400px;
  width: auto;
}
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
